var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "content-header row" }, [
      _c("div", { staticClass: "content-header-left col-md-7 col-12 mb-2" }, [
        _c("div", { staticClass: "row breadcrumbs-top" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h2", { staticClass: "content-header-title float-left mb-0" }, [
              _vm._v(
                _vm._s(_vm.trans("beneficiary.beneficiary")) +
                  "\n                    "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "breadcrumb-wrapper col-12" }, [
              _c("ol", { staticClass: "breadcrumb" }, [
                _c(
                  "li",
                  { staticClass: "breadcrumb-item" },
                  [
                    _c("router-link", { attrs: { to: "/home" } }, [
                      _vm._v(
                        "\n                                    " +
                          _vm._s(_vm.trans("general.home")) +
                          "\n                                "
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "breadcrumb-item" },
                  [
                    _c("router-link", { attrs: { to: "/beneficiaries" } }, [
                      _vm._v(
                        "\n                                    " +
                          _vm._s(_vm.trans("beneficiary.beneficiary")) +
                          "\n                                "
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("li", { staticClass: "breadcrumb-item active" }, [
                  _vm._v(
                    "\n                                " +
                      _vm._s(_vm.trans("beneficiary.beneficiary_details")) +
                      "\n                            "
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "content-header-right text-md-right col-md-5 col-12 d-md-block d-none",
        },
        [
          _c("div", { staticClass: "form-group breadcrum-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary btn-sm pull-right ml-1",
                on: {
                  click: function ($event) {
                    return _vm.$router.push("/beneficiaries/create")
                  },
                },
              },
              [
                _c("i", { staticClass: "feather icon-plus" }),
                _vm._v(
                  " " + _vm._s(_vm.trans("beneficiary.create_beneficiary"))
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary btn-sm pull-right ml-1",
                on: {
                  click: function ($event) {
                    return _vm.$router.push("/beneficiaries")
                  },
                },
              },
              [
                _c("i", { staticClass: "feather icon-list" }),
                _vm._v(" " + _vm._s(_vm.trans("beneficiary.beneficiary_list"))),
              ]
            ),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content-body" }, [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-header" }, [
          _c("h4", { staticClass: "card-title float-left mb-0" }, [
            _vm._v(_vm._s(_vm.trans("beneficiary.beneficiary_details"))),
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass:
                "btn btn-primary btn-sm mr-1 mb-1 waves-effect waves-light pull-right",
              on: { click: _vm.approve },
            },
            [
              _c("i", { staticClass: "feather icon-copy" }),
              _vm._v(
                " " + _vm._s(_vm.trans("beneficiary.approve_this_beneficiary"))
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-content" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-12 col-12" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c("div", { staticClass: "card" }, [
                      _c("div", { staticClass: "card-header" }, [
                        _c("h4", [
                          _vm._v(
                            _vm._s(_vm.trans("beneficiary.beneficiary_details"))
                          ),
                        ]),
                        _vm._v(" "),
                        _c("i", {
                          staticClass:
                            "feather icon-more-horizontal cursor-pointer",
                        }),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "card-body" }, [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12 col-sm-12" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Photo"),
                              ]),
                              _vm._v(" "),
                              _c("img", {
                                attrs: {
                                  src: `/` + _vm.beneficiary.photo,
                                  alt: "",
                                  height: "80",
                                  width: "80",
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(
                                  _vm._s(_vm.trans("beneficiary.eligibility"))
                                ),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.beneficiary.eligibility,
                                    expression: "beneficiary.eligibility",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text", readonly: "" },
                                domProps: {
                                  value: _vm.beneficiary.eligibility,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.beneficiary,
                                      "eligibility",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.trans("beneficiary.admission_number")
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.beneficiary.admission_number,
                                    expression: "beneficiary.admission_number",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text", readonly: "" },
                                domProps: {
                                  value: _vm.beneficiary.admission_number,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.beneficiary,
                                      "admission_number",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(_vm._s(_vm.trans("beneficiary.name"))),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.beneficiary.name,
                                    expression: "beneficiary.name",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text", readonly: "" },
                                domProps: { value: _vm.beneficiary.name },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.beneficiary,
                                      "name",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Unique ID"),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.beneficiary.unique_id,
                                    expression: "beneficiary.unique_id",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text", readonly: "" },
                                domProps: { value: _vm.beneficiary.unique_id },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.beneficiary,
                                      "unique_id",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(_vm._s(_vm.trans("beneficiary.class"))),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.beneficiary.class,
                                    expression: "beneficiary.class",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text", readonly: "" },
                                domProps: { value: _vm.beneficiary.class },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.beneficiary,
                                      "class",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(
                                  _vm._s(_vm.trans("beneficiary.date_of_birth"))
                                ),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.beneficiary.date_of_birth,
                                    expression: "beneficiary.date_of_birth",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text", readonly: "" },
                                domProps: {
                                  value: _vm.beneficiary.date_of_birth,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.beneficiary,
                                      "date_of_birth",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Community"),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.beneficiary.community,
                                    expression: "beneficiary.community",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text", readonly: "" },
                                domProps: { value: _vm.beneficiary.community },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.beneficiary,
                                      "community",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Gender"),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.beneficiary.gender,
                                    expression: "beneficiary.gender",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text", readonly: "" },
                                domProps: { value: _vm.beneficiary.gender },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.beneficiary,
                                      "gender",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Age"),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.beneficiary.age,
                                    expression: "beneficiary.age",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text", readonly: "" },
                                domProps: { value: _vm.beneficiary.age },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.beneficiary,
                                      "age",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(_vm._s(_vm.trans("beneficiary.school"))),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.beneficiary.school.name,
                                    expression: "beneficiary.school.name",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text", readonly: "" },
                                domProps: {
                                  value: _vm.beneficiary.school.name,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.beneficiary.school,
                                      "name",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(
                                  _vm._s(_vm.trans("beneficiary.has_card"))
                                ),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.beneficiary.has_card,
                                    expression: "beneficiary.has_card",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text", readonly: "" },
                                domProps: { value: _vm.beneficiary.has_card },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.beneficiary,
                                      "has_card",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c("div", { staticClass: "card" }, [
                      _vm._m(0),
                      _vm._v(" "),
                      _c("div", { staticClass: "card-body" }, [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12 col-sm-12" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Photo"),
                              ]),
                              _vm._v(" "),
                              _c("img", {
                                attrs: {
                                  src: `/` + _vm.beneficiary.caregiver_photo,
                                  alt: "",
                                  height: "80",
                                  width: "80",
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(_vm._s(_vm.trans("beneficiary.name"))),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.beneficiary.caregiver_name,
                                    expression: "beneficiary.caregiver_name",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text", readonly: "" },
                                domProps: {
                                  value: _vm.beneficiary.caregiver_name,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.beneficiary,
                                      "caregiver_name",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Gender"),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.beneficiary.caregiver_gender,
                                    expression: "beneficiary.caregiver_gender",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text", readonly: "" },
                                domProps: {
                                  value: _vm.beneficiary.caregiver_gender,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.beneficiary,
                                      "caregiver_gender",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Caregiver Date of Birth"),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.beneficiary.caregiver_date_of_birth,
                                    expression:
                                      "beneficiary.caregiver_date_of_birth",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text", readonly: "" },
                                domProps: {
                                  value:
                                    _vm.beneficiary.caregiver_date_of_birth,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.beneficiary,
                                      "caregiver_date_of_birth",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Caregiver Age"),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.beneficiary.caregiver_age,
                                    expression: "beneficiary.caregiver_age",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text", readonly: "" },
                                domProps: {
                                  value: _vm.beneficiary.caregiver_age,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.beneficiary,
                                      "caregiver_age",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Caregiver Phone"),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.beneficiary.caregiver_phone,
                                    expression: "beneficiary.caregiver_phone",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text", readonly: "" },
                                domProps: {
                                  value: _vm.beneficiary.caregiver_phone,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.beneficiary,
                                      "caregiver_phone",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Education Status"),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.beneficiary
                                        .caregiver_education_status,
                                    expression:
                                      "beneficiary.caregiver_education_status",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text", readonly: "" },
                                domProps: {
                                  value:
                                    _vm.beneficiary.caregiver_education_status,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.beneficiary,
                                      "caregiver_education_status",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Employment Status"),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.beneficiary
                                        .caregiver_employment_status,
                                    expression:
                                      "beneficiary.caregiver_employment_status",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text", readonly: "" },
                                domProps: {
                                  value:
                                    _vm.beneficiary.caregiver_employment_status,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.beneficiary,
                                      "caregiver_employment_status",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Relationship Status"),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.beneficiary
                                        .caregiver_relationship_status,
                                    expression:
                                      "beneficiary.caregiver_relationship_status",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text", readonly: "" },
                                domProps: {
                                  value:
                                    _vm.beneficiary
                                      .caregiver_relationship_status,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.beneficiary,
                                      "caregiver_relationship_status",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("h4", [_vm._v("Caregiver Details")]),
      _vm._v(" "),
      _c("i", { staticClass: "feather icon-more-horizontal cursor-pointer" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }